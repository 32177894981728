import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  return (
    <section id="contact" className="p-12 bg-gray-100">
      <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">Contact</h2>
      <p className="text-lg text-gray-700 text-center mb-12">
        Feel free to reach out through any of the following platforms:
      </p>

      <div className="flex justify-center space-x-8">
        {/* LinkedIn */}
        <a href="https://www.linkedin.com/in/sourabh-mahajan-489392117/" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 bg-blue-600 text-white rounded-lg px-4 py-2 transition-transform transform hover:scale-105 hover:bg-blue-700 duration-300">
          <FaLinkedin size={24} />
          <span>LinkedIn</span>
        </a>

        {/* GitHub */}
        <a href="https://github.com/sourabh0110" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 bg-gray-800 text-white rounded-lg px-4 py-2 transition-transform transform hover:scale-105 hover:bg-gray-900 duration-300">
          <FaGithub size={24} />
          <span>GitHub</span>
        </a>

        {/* Email */}
        <a href="mailto:sourabhmmahajan0110@gmail.com" className="flex items-center space-x-2 bg-red-600 text-white rounded-lg px-4 py-2 transition-transform transform hover:scale-105 hover:bg-red-700 duration-300">
          <FaEnvelope size={24} />
          <span>Email</span>
        </a>
      </div>
    </section>
  );
};

export default Contact;
