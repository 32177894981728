import React from 'react';
import sourabhImage from '../assets/sourabh.jpg'


const About = () => {
    return (
      <section id="about" className="p-8">
        <h2 className="text-4xl font-bold mb-6 text-center text-gray-800">About Me</h2>
  
        {/* Container for image and text */}
        <div className="flex flex-col items-center md:flex-row md:items-center md:space-x-8">
          
          {/* Circular Image */}
          <img
            src={sourabhImage}  // Replace with your image path
            alt="Sourabh"
            className="w-40 h-40 rounded-full object-cover shadow-lg mb-4 md:mb-0"
          />
          
          {/* Text about you */}
          <div className="ml-4">
            <p className="text-lg text-gray-700 mb-4">
              Experienced Frontend Engineer with over 7 years of hands-on experience in building high-performance, scalable web applications using modern technologies. Skilled in React, Angular, JavaScript, TypeScript, and Node.js, with a keen focus on delivering intuitive user experiences. Adept at leading cross-functional teams to develop and deploy innovative solutions that align with business goals, ensuring timely project completion and client satisfaction.
            </p>
            <p className="text-lg text-gray-700 mb-4">
              A proven track record of managing the full software development lifecycle, from initial planning and requirements gathering to implementation, testing, and production release. Passionate about writing clean, maintainable code and implementing scalable architecture. Highly focused on mentoring junior developers, promoting best practices, and fostering a culture of continuous learning within teams.
            </p>
            <p className="text-lg mt-4 mb-12">
              'With a passion for creating high-quality applications and a commitment to continuous learning, I strive to deliver innovative solutions that meet user needs.'
            </p>
            {/* <h3 className="text-xl font-semibold text-blue-600 mb-4">Key Technologies:</h3> */}

  {/* Chip-style tech tags */}
  <div className="flex flex-wrap gap-3">
    <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full shadow-sm">
      ReactJS
    </span>
    <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full shadow-sm">
      Angular 2+
    </span>
    <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full shadow-sm">
      Next.js
    </span>
    <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full shadow-sm">
      Node.js
    </span>
    <span className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full shadow-sm">
      Typescript
    </span>
    <span className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full shadow-sm">
      HTML5
    </span>
    <span className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full shadow-sm">
      CSS3
    </span>
    <span className="px-3 py-1 bg-purple-100 text-purple-800 rounded-full shadow-sm">
      Tailwind CSS
    </span>
    <span className="px-3 py-1 bg-red-100 text-red-800 rounded-full shadow-sm">
      Ionic Framework
    </span>
    <span className="px-3 py-1 bg-red-100 text-red-800 rounded-full shadow-sm">
      Apache Cordova
    </span>
    <span className="px-3 py-1 bg-red-100 text-red-800 rounded-full shadow-sm">
      PhoneGap
    </span>
    <span className="px-3 py-1 bg-indigo-100 text-indigo-800 rounded-full shadow-sm">
      MongoDB
    </span>
    <span className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full shadow-sm">
      Jenkins (CI/CD)
    </span>
    </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default About;
  