import React from 'react';
import './App.css';
import Header from './components/Header';
import About from './components/About';
import Responsibilites from './components/Responsibilites';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <main className="mt-4">
        <About />
        <Projects />
        <Responsibilites />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
