import React from 'react';

const Projects = () => {
    return (
      <section id="projects" className="p-12 bg-gradient-to-b from-white to-gray-100 min-h-screen">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-16">
          Projects
        </h2>
  
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">

            {/* TargetX Project */}
          <div className="relative bg-white shadow-lg rounded-xl p-8 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
            <h3 className="text-xl font-semibold text-blue-600 mb-3 border-b-2 pb-2 border-blue-500 hover:text-blue-800 transition-colors">
              <a href="https://gyanmatrix.com/" target="_blank" rel="noopener noreferrer">
                Gyanmatrix Technologies
                <h5 className='text-xs text-black'>{'(Nov 2022 - Present)'}</h5>
              </a>
            </h3>
            <p className="text-gray-600 mb-4">
            <a href="https://www.targetx.com/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue:800">
                TargetX
              </a> Trusted Higher Ed CRM Solutions for Full Student Lifecycle Management which is focused on company building enterprise software that will challenge colleges and universities to transform the way they support their prospects, students and alumni.
            </p>
            <div className="text-sm text-gray-500">
              <p className="font-bold mb-2">Role:</p>
              <ul className="list-disc list-inside ml-4">
                <li>Designing variety of products, developed using various tech staks</li>
                <li>Developed Custom components in react which are used across multiple teams and multiple products</li>
              </ul>
              <p className="font-bold mt-4">Environment:</p>
              <p>ReactJS, Angular 8+, AngularJS, NodeJS, Salesforce Integration</p>
            </div>
          </div>
          
          {/* EventBay Project */}
          <div className="relative bg-white shadow-lg rounded-xl p-8 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
            <h3 className="text-xl font-semibold text-blue-600 mb-3 border-b-2 pb-2 border-blue-500 hover:text-blue-800 transition-colors">
              <a href="https://www.publicissapient.com" target="_blank" rel="noopener noreferrer">
                Publicis Sapient
                <h5 className='text-xs text-black'>{'(Mar 2022 - Nov 2022)'}</h5>
              </a>
            </h3>
            <p className="text-gray-600 mb-4">
              EventBay is a web application that centralizes XT event management. Users can create events or apply as presenters. Admins can approve or reject events and presenters.
            </p>
            <div className="text-sm text-gray-500">
              <p className="font-bold mb-2">Role:</p>
              <ul className="list-disc list-inside ml-4">
                <li>Designed, developed, and deployed the app</li>
                <li>Server-side scripting, REST APIs, components, Docker images</li>
                <li>State management using Redux</li>
              </ul>
              <p className="font-bold mt-4">Environment:</p>
              <p>ReactJS, MongoDB, NodeJS, ExpressJS, GraphQL, Docker, Redux</p>
            </div>
          </div>

          {/* EKAL Project */}
          <div className="relative bg-white shadow-lg rounded-xl p-8 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
            <h3 className="text-xl font-semibold text-blue-600 mb-3 border-b-2 pb-2 border-blue-500 hover:text-blue-800 transition-colors">
              <a href="https://www.winspiresolutions.com/" target="_blank" rel="noopener noreferrer">
                Winspire Technologies
                <h5 className='text-xs text-black'>{'(July 2020 - Feb 2022)'}</h5>
              </a>
            </h3>
            <p className="text-gray-600 mb-4">
              EKAL is a service platform for personal care, household care, and other services in Saudi Arabia. It integrates QR code payments and PayPal for smooth transaction management.
            </p>
            <div className="text-sm text-gray-500">
              <p className="font-bold mb-2">Role:</p>
              <ul className="list-disc list-inside ml-4">
                <li>Designed, developed, and deployed the app</li>
              </ul>
              <p className="font-bold mt-4">Environment:</p>
              <p>Ionic 4, Angular 8+, Microsoft Dynamics Business Central</p>
            </div>
          </div>
  
          {/* GeoMap Project */}
          <div className="relative bg-white shadow-lg rounded-xl p-8 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
            <h3 className="text-xl font-semibold text-blue-600 mb-3 border-b-2 pb-2 border-blue-500 hover:text-blue-800 transition-colors">
              <a href="https://www.tcs.com" target="_blank" rel="noopener noreferrer">
                TCS
                <h5 className='text-xs text-black'>{'(Jan 2017 - Jun 2020)'}</h5>
              </a>
            </h3>
            <p className="text-gray-600 mb-4">
            <a href="https://www.holcim.com/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue:800">
                Lafarge Holcim
              </a> - GeoMap under the client lafargeholcim is an offline-first mobile app created for LafargeHolcim to provide users in Africa with limited internet access the ability to upload photos and GPS locations offline and submit when back online.
            </p>
            <div className="text-sm text-gray-500">
              <p className="font-bold mb-2">Role:</p>
              <ul className="list-disc list-inside ml-4">
                <li>Designed, developed, and deployed the app to Play Store and App Store</li>
                <li>Server-side scripting for push notifications</li>
              </ul>
              <p className="font-bold mt-4">Environment:</p>
              <p>Ionic 3, Angular 6+, NodeJS, Google Firebase DB</p>
            </div>
          </div>

        </div>
      </section>
    );
  };
  
  
  
  export default Projects;
  