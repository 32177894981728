import React from 'react';

const Responsibilites = () => {
    return (
      <section id="responsibilites" className="p-8 bg-gray-100">
        <h2 className="text-4xl font-bold mb-10 text-center text-gray-800">Responsibilites & Acheivements</h2>
  
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Card: Responsibilities */}
          <div className="bg-white p-6 rounded-xl shadow-xl transform transition duration-500 hover:scale-105">
            {/* Key Responsibilities */}
            <h4 className="font-bold text-gray-800 mb-4">Key Responsibilities</h4>
            <div className="space-y-4">
              <div className="p-4 bg-blue-50 rounded-md shadow-sm">
                <h5 className="font-bold text-blue-600">Team Leadership</h5>
                <p className="text-gray-700 mt-1">
                  Led a team of developers in designing and implementing dynamic web solutions using modern frameworks.
                </p>
              </div>
              <div className="p-4 bg-blue-50 rounded-md shadow-sm">
                <h5 className="font-bold text-blue-600">Collaboration</h5>
                <p className="text-gray-700 mt-1">
                  Worked closely with cross-functional teams to define project scope, objectives, and deliverables.
                </p>
              </div>
              <div className="p-4 bg-blue-50 rounded-md shadow-sm">
                <h5 className="font-bold text-blue-600">Project Management</h5>
                <p className="text-gray-700 mt-1">
                  Managed project timelines and ensured on-time delivery of solutions, boosting client satisfaction.
                </p>
              </div>
              <div className="p-4 bg-blue-50 rounded-md shadow-sm">
                <h5 className="font-bold text-blue-600">Mentorship</h5>
                <p className="text-gray-700 mt-1">
                  Conducted code reviews and mentored junior developers, promoting continuous learning within the team.
                </p>
              </div>
            </div>
          </div>
  
          {/* Right Card: Notable Achievements */}
          <div className="bg-white p-6 rounded-xl shadow-xl transform transition duration-500 hover:scale-105">
            <h4 className="font-bold text-gray-800 mb-4">Notable Achievements</h4>
            <div className="space-y-4">
              <div className="p-4 bg-green-50 rounded-md shadow-sm">
                <h5 className="font-bold text-green-600">Probat Project</h5>
                <p className="text-gray-700 mt-1">
                  Led the innovative project “Probat” (Native Android), focusing on enhancing user engagement and experience.
                </p>
              </div>
              <div className="p-4 bg-green-50 rounded-md shadow-sm">
                <h5 className="font-bold text-green-600">On-time Delivery Award</h5>
                <p className="text-gray-700 mt-1">
                  Recognized with the “On-time Delivery” award for consistent project management and execution.
                </p>
              </div>
              <div className="p-4 bg-green-50 rounded-md shadow-sm">
                <h5 className="font-bold text-green-600">Customer Satisfaction Award</h5>
                <p className="text-gray-700 mt-1">
                  Received the “Customer Satisfaction” award, reflecting strong client relationships and successful outcomes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default Responsibilites;
  